.HM-View {
    text-align: center;
    min-height: 80vh;
    width: 100vw;
    background-image: linear-gradient(#161616, #121212);
    padding-bottom: 120px;
    color:white;
}


.HM-View h1 {
    padding-top: 20vh;
    font-size: 52px;
}
.HM-View h2 {
    padding-top: 10vh;
    font-size: 30px;
}
.HM-View h3 {
    padding-top: 30vh;
    font-size: 20px;

    width: 40%;
    margin-left: 30%;
}

.HM-View p {
    padding-top: 15vh;
    font-size: 20px;

    width: 40%;
    margin-left: 30%;
}