.platform-container {
    background: rgb(50,50,50);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    object-fit: contain;
}
.platform-container h1 {
    /*padding-top: 12%;*/
    color: white;
    font-family: PlatformClimber, serif;
    font-size: 92px;
}
.platform-container h2 {
    padding-top: 2%;
    color: white;
    font-family: PlatformClimber, serif;
    font-size: 24px;
    width: 40%;
    text-align: center;
    /*margin-left: 0%;*/
}
.platform-container p {
    /*padding-top: 12%;*/
    color: white;
    font-family: PlatformClimber, serif;
    font-size: 20px;
    letter-spacing: 4px;
}
.platform-container * {
    font-family: PlatformClimber, serif;

    font-weight: bolder;
    letter-spacing: 1px;
}

@media only screen and (max-width: 1020px) {
    .platform-container h1 {
        font-size: 42px;
    }
}