.platform-highscore {
    background: rgb(50,50,50);
    width: 100vw;
    padding-bottom: 10vh;
}

.platform-highscore h1 {
    font-family: PlatformClimber, serif;
    text-align: center;
    letter-spacing: -1px;
    font-size: 60px;
    color: rgb(220,250,220);
}

.platform-score{
    text-align: center;
    font-family: PlatformClimber, serif;
    letter-spacing: 1px;
    font-size: 30px;
    color: rgb(250,250,250);
}