.lng-img-div img{
    width: 40px;
    height: 80px;
    margin-top: 20px;
    position: absolute;
}
.lng-img-div h4{
    position: absolute;
    width: 40px;
    height: 80px;
    font-size: 13px;
}
.lng-img-div {
    width: 7.5vw;
    min-width: 30px;
    height: 100px;
    margin: 10px 1.5vw 10px;
    max-height: 200px;
    display: flex;
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    alignment: center;
    align-content: normal;
    /*min-width: 300px;*/
    float: left;
}

.lng-img-container {
    width: 85vw;
    align-content: center;
    alignment: center;
    align-items: center;
}