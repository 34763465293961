.info{
    height: 100vh;
    text-align: center;
    background: url("/public/images/main/infoBackground.jpg");
}
.info h2 {
    padding-top: 6%;
    color: white;
    font-size: 3em;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
}

.info h3 {
    padding-top: 6%;
    color: white;
    font-size: 2em;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
}

.info .caption {
    padding: 5px 0 10px;
    color: white;
    font-size: 1em;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
}

.info p {
    padding-top: 10px;
    color: white;
    font-size: 1.5em;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
}
