:root {
    --bg-color:rgb(20,20,20);
    --mouse-x:0px;
    --mouse-y:0px;
    --hover-color:#ffffff10;
}

.overviewTag{
    padding: 3px 40px;
    margin: 4px 8px;
    /*width: min-content;*/
    float: left;
    border-radius: 8px;
    position:  relative;

    background: var(--bg-color);
    cursor: pointer;
    text-align: center;
    justify-content: center;

    color: white;
    font-size: 20px;
    letter-spacing: 1px;
    z-index: 20;

}
.overviewTag:hover::before {
    opacity: 1;
}

.overviewTag::before {
    background: radial-gradient(180px circle at var(--mouse-x) var(--mouse-y), var(--hover-color), transparent 70%);
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0;
    transition: all 500ms;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    color: white;
}
