.pageNotFound{
    height: 100vh;
    text-align: center;
    background: url("/public/images/main/404background.jpg");
}
.pageNotFound h1 {
    padding-top: 12%;
    color: black;
    font-family: "Comic Sans MS", serif;
    font-size: 92px;
}
.pageNotFound h2 {
    /*padding-top: 1%;*/
    font-family: "Comic Sans MS", serif;
    font-size: 40px;
}