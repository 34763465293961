.simulations-main-view {
    text-align: center;
    min-height: 100vh;
    width: 100vw;
    padding-bottom: 120px;
    background-color: #0f0c29; /* Dark background */
    color: white;
}

.simulations-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.simulations-gallery:hover {
    color: #00ff00; /* RGB color change on hover for interactivity */
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
    .simulations-gallery {
        grid-template-columns: 1fr;
    }
}
