.mushroom {
    /*float: inside;*/
    /*position: relative;*/
    background: #3D3F41;
    text-align: center;
    color: white;
    padding-bottom: 30px;
    height: fit-content;
    min-height: calc(clamp(20vw, 300px, 200px)*85.7/10);
}

p {
    font-family: "SF Compact", serif;
}
.mushroom h1 {
    padding-top: 5%;
    font-size: 92px;
    color: white;
}
.mushroom h2 {
    font-size: 50px;
    color: white;
}
.mushroom h3 {
    padding-top: 8%;
    font-size: 30px;
    color: white;
}
.mushroom h4 {
    padding-top: 12%;
    padding-bottom: 4%;
    font-family: "Comic Sans MS", serif;
    font-size: 22px;
    color: gray;

}
.projectOverview {
    /*position: absolute;*/
    float: left;
    flex: auto;
    text-align: center;
    width: clamp(20vw, 300px, 200px);
    /*aspect-ratio: 212/1714;*/
    height: fit-content;
    z-index: 20;
}


.projectOverview h1{
    font-size: 20px;
    color: white;
}
.projectOverview h4{
    padding-top: 5px;
    font-size: 16px;
    color: gray;
}

.projectOverviewImage {
    /*position: absolute;*/
    background: url("/public/images/mushroom/FungoProjectFile-Oct22.png") center center/cover no-repeat #3D3F41;
    width: clamp(20vw, 300px, 200px);
    aspect-ratio: 10.6/85.7;
    z-index: 10;
}

.gamePlayImage {
    margin-left: calc(5/4*clamp(30vw, 350px, 250px));
    width: calc(100vw - 3/2*5/4*clamp(30vw, 350px, 250px));
    border: 4px solid lightgray;
    border-radius: 15px;
    aspect-ratio: 4/3;
    padding-top: 14vh;
    z-index: 1005;
    transition: left ease 400ms;
}

.gamePlayImageFullscreen {
    position: fixed;
    border: 4px solid lightgray;
    border-radius: 15px;
    aspect-ratio: 4/3;
    z-index: 1005;
    transition: left ease 600ms;

}
.gamePlayImageFullscreen.fullscreen {
    position: fixed;
    top: 80px;
    left: 2.5vw;
    margin: 0;
    padding: 0;
    width: 95vw;
    height: calc(100vh - 80px);
}
.gamePlayImageFullscreen.fullscreen.left {
    left: -100%;
}
.gamePlayImageFullscreen.fullscreen.right {
     left: 100%;
 }
.mushroomImage1{
    background: url("/public/images/mushroom/FungoGameplay1-Oct22.png") center center/cover no-repeat #202020;

}
.mushroomImage2{
    background: url("/public/images/mushroom/FungoGameplay2-Oct22.png") center center/cover no-repeat #202020;
}
.mushroomImage3{
    background: url("/public/images/mushroom/FungoGameplay3-Oct22.png") center center/cover no-repeat #202020;
}

@media screen and (max-width: 900px) {
    /*  Small Screen: less than 900  */
    .gamePlayImageFullscreen.fullscreen {
        top: 0;
        height: 100vh;
        transition: left ease 400ms;

    }
    .projectOverviewImage {
        width: 0;
    }
    .projectOverview {
        width: 0;
        position: absolute;
        height: 0;
        z-index: -10;
    }

    .gamePlayImage {
        width: 90vw;
        margin: 0 0 0 5vw;
    }

    .mushroom h1 {
        padding-top: 12%;
        font-size: 72px;
        color: white;
    }
}