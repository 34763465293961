.features {
  padding: 9rem;
  background-color: #000;
  background: url('../../../public/images/soundboard/features.jpg') center center/cover no-repeat;
  background-position-y: top;
  /* background-position: ; */
  height:720px;
  text-align: center;
}

.description {
  text-align: center;
  color:#eee;
}
.description p {
  font-size: 24px;
}

.features__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  /* height: 10; */
}

.features__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.features__items {
  margin-bottom: 24px;
  margin-top: 30px;
  /* width: 30%; */
}

.features__item {
  display: flex;
  background-color:rgba(1, 1,1, 1);
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;

  width: 260px;
  /* height: 240px; */

  /* width: 110px;
  height: 130px; */
}

.features__item__text {
  font-size: 18px;
  line-height: 24px;
  word-wrap: break-word;
  padding-left: 10px;
  padding-right: 10px;
}

.features__item__img {
  padding-top: 10px;
  width: 240px;
  height: 180px;
}


.features__item__link {
  display: flex;
  text-align: center;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: #bbb;
}

@media only screen and (min-width: 1200px) {
  .features__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .features__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .features__item {
    margin-bottom: 2rem;
  }
}

