.dnd-features-container {
    background: url('/public/images/dnd-languages/background.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    text-align: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    object-fit: contain;
}

.dnd-features-container h1 {
    padding-top: 5%;
    font-family: "Chalkboard", serif;
    font-size: 92px;
}
.dnd-features-container p {
    padding-top: 2%;
    font-family: "Chalkboard", serif;
    font-size: 22px;
    font-weight: bolder;

}

@media only screen and (max-width: 1020px) {
    .dnd-features-container h1 {
        font-size: 42px;
    }
}
