
.website-features-wrapper {
    display: flex;
}
.website-features {
    width: 30vw;
    aspect-ratio: 1.2;
    border-radius: 20px;
    border-style: solid;
    border-color: white;
    border-width: 3px;
    /*position: absolute;*/
    margin-top: 2.6vw;
    margin-left: 2.6vw;
    /*display: flex;*/
    /*float: left;*/

    background: rgb(186, 217, 246);
}

.website-features:hover {
    border-color: #ededed;
}

.website-features img {
    width: 80%;
    border-radius: 40px;
}

.website-features h1 {
    padding-top: 20px;
    text-align: center;
    font-size: 30px;
    width: 100%;
}
.website-features p {
    text-align: center;
    font-size: 16px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .website-features {
        width: 80vw;
        margin-left: 10vw;
        float: none;
        margin-top: 20px;
    }
    .website-features-wrapper {
        display: block;
    }
}
