.braille {
    height: 100vh;
    background: #202020;
    text-align: center;
}
.braille h1 {
    padding-top: 12%;
    color: darkgrey;
    font-family: "SF Compact", serif;
    font-size: 92px;
}
.braille h2 {
    padding-top: 3%;
    font-size: 40px;
    font-family: Braille, serif;
    color: white;
}
