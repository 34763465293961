.dnd-container {
    background: url('/public/images/dnd-languages/background.jpg') center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    object-fit: contain;
}
.dnd-container h1 {
    /*padding-top: 12%;*/
    font-family: "Chalkboard SE", serif;
    font-size: 92px;
}

@media only screen and (max-width: 1020px) {
    .dnd-container h1 {
        font-size: 42px;
    }
}
