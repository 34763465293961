.cards {
  padding: 4rem;
  background: linear-gradient(#000000, #171720, #181820, #181818);
}

.cards h1 {
  text-align: center;
  color: #eeeeee;
}
.cards a {
  color: #ddddff;
  text-decoration: none;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  min-height: 300px;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
  margin-top: 30px;
}

.cards__item {
  display: flex;
  background-color: #242222;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  width: 110px;
  height: 130px;
}

.cards__item:hover {
  transform: scale(1.1);
  background-color:#252e48;
}

.cards__item__text {
  font-size: 18px;
  line-height: 24px;
  color: #dddddd;
}
.cards__item__link:hover{
    color: white;
}

.cards__item__link {
  display: flex;
  text-align: center;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  color: #252e48;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

