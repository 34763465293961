.morse-body{
    height: 100vh;
    background: gainsboro;
    text-align: center;
    background: url('/public/images/morse/background.jpg') center center/cover no-repeat;
}
.morse-body h1 {
    color: lightgray;
    font-family: MorseCode;
    padding-top: 12%;
    /*font-family: "Comic Sans MS", serif;*/
    font-size: 92px;
}
.morse-body h2 {
    color: white;
    padding-top: 2%;
    font-family: "Courier New", sans-serif;
    font-size: 30px;
}


@media only screen and (max-width: 1200px) {
    .morse-body h1 {
        font-size: 32px;
    }
    .morse-body h2 {
        font-size: 14px;
    }
}