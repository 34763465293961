.overviewProject{
    /*height: 350px;*/
    width: 100%;
    height: 100%;
    border-radius: 10px;
    text-align: center;


    background: #262626;
    justify-content: center;
    padding-top: 10px;
    position: relative;
}
.overviewProjectTags {
    padding-bottom: 20px;
    /*position: absolute;*/
    bottom: 0;
}


.overviewProjectImage {
    background: center center/cover no-repeat #202020;
    max-width: 70%;
    max-height: 300px;
    /*aspect-ra tio: 1.5;*/
    /*height: 400px;*/
    margin: 20px 15% 20px;
    /*border: 4px solid lightgray;*/
    border-radius: 15px;
    /*padding-top: 14vh;*/
    z-index: 1005;
    transition: left ease 400ms;
}

.overviewProject .description {
    color: white;
    text-align: center;
    font-size: 18px;
    margin: 20px;
}
.overviewProject .date {
    color: grey;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    /*margin: 20px;*/
}
.overviewProject .time {
    color: grey;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    /*margin: 20px;*/
}


.overviewProject ul{
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    max-width: 100%;
    overflow-x: auto;
    flex-wrap: wrap;
}

.overviewProject li{
    color: #fff;
    border-radius: 4px;
    white-space: nowrap;
}

.overviewProject h1 {
    color: white;
    text-shadow: black 0 0 15px;
}
