.tactico-container {
    background: #2B2E40;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);*/
    object-fit: contain;
}
.tactico-container h1 {
    /*padding-top: 12%;*/
    color: #CBFF00;
    font-size: 92px;
}
.tactico-container h2 {
    padding-top: 2%;
    color: white;
    font-size: 24px;
    width: 40%;
    text-align: center;
    /*margin-left: 0%;*/
}
.tactico-container p {
    /*padding-top: 12%;*/
    color: white;
    font-size: 20px;
    letter-spacing: 4px;
}
.tactico-container h3 {
    /*padding-top: 12%;*/
    color: white;
    font-size: 18px;
    padding-left:30px;
    padding-right:30px;
}
.tactico-container * {
    font-weight: bolder;
    letter-spacing: 1px;
    font-family: "Arial Rounded MT Bold";
}

@media only screen and (max-width: 1020px) {
    .tactico-container h1 {
        font-size: 42px;
    }
}