.Api-View{
    text-align: center;
    min-height: 80vh;
    width: 100vw;
    background-image: linear-gradient(#161616, #121212);
    padding-bottom: 120px;
    color:white;
}


.Api-View h1 {
    padding-top: 20vh;
    font-size: 52px;
}
.Api-View h2 {
    padding-top: 10vh;
    font-size: 30px;
}
.Api-View p {
    padding-top: 30vh;
    font-size: 20px;
}

.Api-feature-wrapper {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    height: 32vh;
    justify-content: center;

    background: #121212;
}
.Api-feature {
    text-align: center;
    width: 30%;
    height: 30vh;
    color: white;
    background: #404040;
    margin: 0 10px;
    padding: 10px;

    border-radius: 14px;
}
.Api-feature div {
    align-items: center;
    vertical-align: center;

    width: 90%;
    height: 80%;

    float: left;
}
.Api-feature h1 {
    padding: 0;
    height: 20%;
}
.Api-feature p {
    font-size: 18px;
    padding-left: 20px;
    width: 60%;
    float: left;
}

.Api-feature img{
    /*max-height: 100px;*/
    width:25%;
    aspect-ratio: 1;
    margin-left: 20px;
    filter: invert(100%);
    float: left;
}

@media screen and (max-width: 1020px) {
    .Api-feature div {
        display: block;
        float:left;
        width: 90%;
    }

    .Api-feature-wrapper {
        height: 42vh;
    }

    .Api-feature {
        width: 30%;
        height: 40vh;
    }
}

@media screen and (max-width: 920px) {

    .Api-View h1{
        font-size: 30px;
    }
    .Api-View h2{
        font-size: 20px;
    }

    .Api-feature div {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 3fr;
        width: 90%;
    }

    .Api-feature-wrapper {
        height: calc(750px + 4vh + 2vh);
    }

    .Api-feature {
        width: 95%;
        height: 250px;
        margin-bottom: 2vh;
    }
}