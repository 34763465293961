* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'MainFontBold', sans-serif;

  -ms-overflow-style: none; /* Edge, Internet Explorer */
  scrollbar-width: none; /* Firefox */
  overscroll-behavior: none;
}

*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.MainView {
  text-align: center;
  min-height: 100vh;
  width: 100vw;
  background-image: linear-gradient(#f3ffff, rgb(162, 211, 255));
  padding-bottom: 120px;

}
.MainView h1 {
  padding-top: 20vh;
  font-size: 52px;
}
.MainView h2 {
  padding-top: 10vh;
  font-size: 30px;
}
.MainView p {
  padding-top: 30vh;
  font-size: 20px;
}

.logo-navbar {
  background: url("/public/images/main/logoWhite.svg") center center/cover no-repeat;
  aspect-ratio: 1;
  width: 48px;
}

.logo-footer {
  background: url("/public/images/main/logoWhite.svg") center center/cover no-repeat;
  aspect-ratio: 1;
  width: 32px;
}
.blackAndWhite {
  filter: grayscale(1);
}


@font-face {
  font-family: "Minecraft";
  src: url('/public/fonts/minecraft/MinecraftRegular-Bmg3.otf');
}
@font-face {
  font-family: "Minecraft Bold";
  src: url('/public/fonts/minecraft/MinecraftBold-nMK1.otf');
}

@font-face {
  font-family: "MorseCode";
  src: url('/public/fonts/morse/MorseCoder-RppRE.ttf');
}
@font-face {
  font-family: "PlatformClimber";
  src: url('/public/fonts/platform-climber/platform-climber.ttf');
}

@font-face {
  font-family: "Braille";
  src: url('/public/fonts/braille/Braille-1lA2.ttf');
}

@font-face {
  font-family: "MainFont";
  src: url('/public/fonts/PTSans-Regular.ttf');
}

@font-face {
  font-family: "MainFontBold";
  src: url('/public/fonts/PTSans-Bold.ttf');
}

@font-face {
  font-family: "MainFontItalic";
  src: url('/public/fonts/PTSans-Italic.ttf');
}

@font-face {
  font-family: "MainFontBoldItalic";
  src: url('/public/fonts/PTSans-BoldItalic.ttf');
}
