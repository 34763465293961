.lng-item {
    width: 25vw;
    min-width: 300px;
    height: 40vh;
    margin: 10px 1.5vw 10px;
    max-height: 200px;
    display: flex;
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    background: burlywood;
    alignment: center;
    align-content: normal;
    /*min-width: 300px;*/
    border-width: 1px;
    border-style: solid;
    border-color: #1c1b1b;
    float: left;
}
.lng-item h2 {
    margin: 60px 2vw;
    position: absolute;
}
.lng-item h4 {
    margin: 45px 12vw;
    width: 8vw;
    text-align: center;
    position: absolute;
}
.lng-item button {
    position: absolute;
    margin: 90px 12vw;
    width: 8vw;
    /*min-width: 100px;*/
    height: 40px;

    color: black;

    background: moccasin;
    border-style: none;
    border-radius: 20px;
}
.lng-item button:hover {
    background: #ffd689;
    border-style: double;
    border-color: #b2832f;
    border-width: 1px;
}

.lng-list-container {
    background: navajowhite;

}
.lng-item-container {
    flex-flow: column;
    align-items: center;
    margin: 0 auto;
    height: 220px;
    /*width: 100%;*/
    vertical-align: middle;
    width: 85vw;
    min-width: 1020px;
}

.lng-item:hover {
    transform: scale(1.01);
}

@media only screen and (max-width: 1020px) {
    .lng-item-container {
        display: flex;
        height: 500px;
        min-width: 0;
    }

    .lng-item h2{
        margin: 0;
        text-align: center;
        width: 25vw;
        min-width: 300px;
    }
    .lng-item h4{
        margin: 50px 0 0;
        text-align: center;
        width: 25vw;
        min-width: 300px;
    }
    .lng-item button{
        margin: 80px 0;
        /*margin: 80px 10vw 0;*/
        width: 25vw;
        min-width: 275px;
        /*max-width: 120px;*/
        height: 40px;
    }
}

@media only screen and (min-width: 1024px) {
    .lng-item-container {
        display: block;
    }
}


